/* You can add global styles to this file, and also import other style files */
.card > .card-body > .card-title > .btn-toolbar.justify-content-between h5.d-inline {
  margin-left: 7px;
  position: relative;
  top: 1.5px;
}

.form-control:focus {
  border-color: #76b1a1;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(127, 205, 191, 0.75);
}
